import { InMemoryCache } from '@apollo/client';
import _merge from 'lodash/merge';
import { Alert } from '@blocs.common';

// use this after instantiating the cache to initialize the cache
// for client-only state
export const INIT_CACHE_STATE = (inMemCache: InMemoryCache): void => {
  Alert.initializeAlertState(inMemCache);
};

export const RESOLVERS = () => _merge(Alert.globalAlertResolvers);

// spread this into the ApolloClient config
export const BASE_CONFIG = () => ({
  resolvers: RESOLVERS(),
});
