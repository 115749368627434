import { Yup } from '@minecraft.utils';

const RgxEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

/**
 * @deprecated due to the fact that this causes a memory leak and crashes the browser
 * Simply use the Yup.string().email() validation instead (preferably as a Yup schema)
 * validateEmail
 * @param email
 * @return {boolean}
 */
const validateEmail = (email) => RgxEmail.test(email);

/**
 * validateWhiteSpaceString
 * @param string
 * @param length
 * @return {boolean}
 */
const validateWhiteSpaceString = (string, length) => {
  if (string) {
    if (string.trim().length > 0 && string.trim().length <= length) {
      return true;
    }
  }

  return false;
};

/**
 * isValidEmail
 * @param value
 * @return {boolean}
 */
const isValidEmail = (value: string): boolean => {
  try {
    if (!value) {
      return false;
    }
    Yup.string().email().validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
};

const Email = {
  validateEmail,
  validateWhiteSpaceString,
  isValidEmail,
};

export default Email;
