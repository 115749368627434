import { ACCESS_TOKEN, REFRESH_TOKEN, ONBOARDING_TOKEN, MARKETING_REDIRECT } from '../constants';

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const deleteAccessToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const deleteRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN);
};

export const clearStorage = (): void => {
  deleteAccessToken();
  deleteRefreshToken();
  sessionStorage.clear();
};

// onboarding
export const getOnboardingToken = (): string => {
  return localStorage.getItem(ONBOARDING_TOKEN);
};

export const setOnboardingToken = (token: string): void => {
  localStorage.setItem(ONBOARDING_TOKEN, token);
};

export const deleteOnboardingToken = (): void => {
  localStorage.removeItem(ONBOARDING_TOKEN);
};

// marketing redirect
export const getMarketingRedirect = (): string => {
  return localStorage.getItem(MARKETING_REDIRECT);
};

export const removeMarketingRedirect = (): void => {
  localStorage.removeItem(MARKETING_REDIRECT);
};

export const saveMarketingRedirect = (redirectUrl: string): void => {
  localStorage.setItem(MARKETING_REDIRECT, redirectUrl);
};
