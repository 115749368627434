import { ApolloClient, NormalizedCacheObject, from } from '@apollo/client';
import { createBasicHttpLink, buildAuthErrorLink, authLink, userCountryLink, PRODUCTION } from '@blocs.auth';
import { getEnvironment } from '@minecraft.environment';
import cache from './cache';
import { INIT_CACHE_STATE, BASE_CONFIG } from './config';

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    // base config includes resolvers for Alert component
    ...BASE_CONFIG(),
    connectToDevTools: getEnvironment()?.ENV !== PRODUCTION,
    link: from([buildAuthErrorLink({ redirectTo: '/login' }), authLink, userCountryLink, createBasicHttpLink()]),
    cache,
    name: 'web',
    version: 'login',
  });

  INIT_CACHE_STATE(cache);

  return client;
}

export default createApolloClient;
