import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Property } from 'csstype';
import { useTheme } from 'styled-components';

import { Theme } from '@minecraft.themes';
import {
  computeBorderStyle,
  computeContainerStyle,
  computeBorderRadiusStyle,
  computeOffsetStyle,
  getGridItemStyles,
  getMarginClassnames,
  getWidthClassnames,
} from '../helpers';
import { BorderProps, ContainerStyleProps, FlexProps, FlexStyleProps } from '../types';

const getFlexStyles = ({
  alignContent,
  alignItems = 'flex-start',
  bgColor,
  borderBottom = false,
  borderColor,
  borderPlacement = 'none',
  borderSize = 0.06,
  customBorders,
  direction = 'row',
  hidden = false,
  justifyContent = 'flex-start',
  justifyItems,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  reverse = false,
  textColor,
  theme,
  wrap = 'wrap',
  customBordersRadius,
  top,
  right,
  bottom,
  left,
  bgOpacity = 1,
}: FlexStyleProps & BorderProps & ContainerStyleProps & { theme: Theme }) => {
  let flexDirection: Property.FlexDirection = direction;

  if (reverse) {
    flexDirection = direction === 'row' ? 'row-reverse' : 'column-reverse';
  }

  return {
    alignContent,
    alignItems,
    color: theme.designSystem.textColors[textColor],
    display: hidden ? 'none' : 'flex',
    flexDirection,
    flexWrap: wrap,
    justifyContent,
    justifyItems,
    maxHeight: typeof maxHeight === 'number' ? `${maxHeight}rem` : undefined,
    maxWidth: typeof maxWidth === 'number' ? `${maxWidth}rem` : undefined,
    minHeight: typeof minHeight === 'number' ? `${minHeight}rem` : '1px',
    minWidth: typeof minWidth === 'number' ? `${minWidth}rem` : '1px',
    ...computeContainerStyle({ theme, textColor, bgColor, bgOpacity }),
    ...computeBorderStyle({ borderPlacement, borderSize, borderColor, borderBottom, customBorders, theme }),
    ...computeBorderRadiusStyle({
      customBordersRadius,
    }),
    ...computeOffsetStyle({
      top,
      right,
      bottom,
      left,
    }),
  };
};

export const FlexBase: FC<FlexProps> = ({
  children,
  id,
  className,
  qaId,
  'data-testid': dataTestId,
  'data-qa-id': dataQaId,
  gap = '0',
  grow = 1,
  shrink = 1,
  borderRadius = 'none',
  role,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  zIndex,
  ...styleProps
}) => {
  const theme = useTheme() as Theme;
  const style = useMemo(
    () =>
      getFlexStyles({
        ...styleProps,
        theme,
      }),
    [styleProps, theme]
  );

  const gridItemStyle = useMemo(
    () => ({
      ...getGridItemStyles({ ...styleProps, theme }),
      theme,
    }),
    [styleProps, theme]
  );

  const qaIdValue = qaId || dataQaId;
  const optionalProps = useMemo(() => {
    return {
      ...(typeof dataTestId === 'string' && dataTestId ? { 'data-testid': dataTestId } : {}),
      ...(typeof qaIdValue === 'string' && qaIdValue ? { 'data-qa-id': qaIdValue } : {}),
    };
  }, [dataTestId, qaIdValue]);

  return (
    <div
      id={id}
      className={classNames(
        {
          [`cn_atom_zIndex-${zIndex}`]: zIndex !== undefined,
          [`cn_atom_gap-${gap}`]: true,
          [`cn_atom_grow-${grow}`]: true,
          [`cn_atom_shrink-${shrink}`]: true,
          [`cn_atom_rounded-${borderRadius}`]: !styleProps.customBordersRadius,
        },
        getMarginClassnames(styleProps),
        getWidthClassnames(styleProps),
        className
      )}
      {...optionalProps}
      style={{ ...gridItemStyle, ...style }}
      role={role}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
    >
      {children}
    </div>
  );
};
