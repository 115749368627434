import React, { useCallback, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from '@blocs.i18n';
import { appConfig } from '@minecraft.app-config';
import { THEME_NAMES, Theme } from '@minecraft.themes';
import Button from '@minecraft.button';
import { getAccessToken, isTokenExpired, useLogout } from '@blocs.auth';
import { CastItLogo } from '@blocs.global-header';
import { BodyTextLarge, FlexDynamic, LinkAsButton } from '@minecraft.atoms';

type ContainerWithHeaderProps = {
  routeComponent: React.ReactElement;
  showCancelRegistration?: boolean;
  hideLogout?: boolean;
};

type ContainerWithHeaderPropsAndFullHeight = {
  fullHeight?: boolean;
} & ContainerWithHeaderProps;

type StyledHeaderProps = {
  $isStudio?: boolean;
};

const StyledHeaderContainer = styled.div<StyledHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  ${(props) => (props.$isStudio ? `background-color: ${props.theme.colors.primary.studioBkgd};` : '')}
`;

const StyledLogo = styled.img`
  height: 4.5rem;
  display: flex;
  padding: 0.5rem 0;
`;

const CastItLogoContainer = styled.div`
  margin: 0.8rem 0;
`;

const StyledButton = styled(Button)`
  text-decoration: none;
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.buttons.primary.default} !important;
`;

const StyledFullHeightWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ComponentWithHeaderContent: FC<ContainerWithHeaderProps> = ({
  showCancelRegistration,
  routeComponent: RouteComponent,
  hideLogout,
}) => {
  const { t } = useTranslation();
  const [logout] = useLogout(false);
  const theme = useTheme() as Theme;

  const router = useHistory();
  const { type } = useParams<{ type: string }>();

  const logoutHandler = useCallback(() => {
    logout();
  }, [logout]);

  const isStudioTheme = theme?.name === THEME_NAMES.studio;
  const Logo = isStudioTheme ? (
    <CastItLogoContainer>
      <CastItLogo />
    </CastItLogoContainer>
  ) : (
    <StyledLogo alt="Casting Networks Logo" src={`${appConfig.cdnRoot}/images/logos/cn-horizontal.svg`} />
  );

  return (
    <>
      <StyledHeaderContainer $isStudio={isStudioTheme}>
        <Link to="/">{Logo}</Link>
        {!hideLogout && !isTokenExpired(getAccessToken()) && (
          <StyledButton color="link" size="large" onClick={logoutHandler}>
            {t('common:header.logout')}
          </StyledButton>
        )}
        {showCancelRegistration && (
          <FlexDynamic zIndex={3} className="cn_absolute" top={1.5} right={1}>
            <BodyTextLarge as="span">
              <LinkAsButton
                onClick={() => {
                  if (type) router.push('/signup');
                  else router.push('/');
                }}
                variant="solid"
                label={t('common:button.back')}
              />
            </BodyTextLarge>
          </FlexDynamic>
        )}
      </StyledHeaderContainer>
      {RouteComponent}
    </>
  );
};

const ComponentWithHeader: FC<ContainerWithHeaderPropsAndFullHeight> = ({
  showCancelRegistration,
  routeComponent,
  hideLogout,
  fullHeight = false,
}) => {
  return fullHeight ? (
    <StyledFullHeightWrapper>
      <ComponentWithHeaderContent
        showCancelRegistration={showCancelRegistration}
        routeComponent={routeComponent}
        hideLogout={hideLogout}
      />
    </StyledFullHeightWrapper>
  ) : (
    <ComponentWithHeaderContent
      showCancelRegistration={showCancelRegistration}
      routeComponent={routeComponent}
      hideLogout={hideLogout}
    />
  );
};

export default ComponentWithHeader;
