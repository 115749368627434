import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TranslateFn } from '@blocs.i18n';
import Email from './validators/Email';
import { Required } from './validators/Required';
/*
  This is MVP for sharing custom methods with yup.
  Yup discussion can be found here
  https://castingnetworks.atlassian.net/wiki/spaces/AD/pages/36634625/Form+global+validation+schema?atlOrigin=eyJpIjoiYzk2MTYxMmI4Yzc1NDMxZDkxYmVkNzBkMmE3M2Q3OGUiLCJwIjoiYyJ9

  The method would check values of any references past in parameters to input value

  Example of usage.

  const someValidation = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    password: Yup.string().simplePassword([Yup.ref('firstName'), Yup.ref('lastName')], 'Here goes error message')
  })
 */

declare module 'yup' {
  interface BaseSchema {
    customConditionalMsg(msg: string): Yup.StringSchema;
    simplePassword(ref: Reference[], string: string): Yup.StringSchema;
    isPhoneValid(value: string): Yup.StringSchema;
    notOnlySpaces(value: string): Yup.StringSchema;
    notEmpty(value: string): Yup.StringSchema;
    emailCustom(value: string): Yup.StringSchema;
    isValidYear(value: string): Yup.StringSchema;
  }
}

const RgxAtleastNumber = /\d/;
const RgxAtleastSpChar = /\W/;
const MIN_CHARACTERS = 8;

export const password = (t: TranslateFn): Yup.StringSchema =>
  Yup.string()
    .required(t('common:validation.required'))
    .notOnlySpaces(t('common:validation.required'))
    .min(MIN_CHARACTERS, t('common:validation.minCharacters', { min: MIN_CHARACTERS }))
    .matches(RgxAtleastNumber, t('common:validation.atLeastNumber'))
    .matches(RgxAtleastSpChar, t('common:validation.atLeastSpecial'));

Yup.addMethod(Yup.string, 'simplePassword', function (refs: Reference[], message) {
  return this.test('simplePassword', message, function (passwordValue) {
    const values = refs
      .map((ref) => this.resolve(ref)) // map input refs to values
      .filter((value: any) => value && value.length >= 4) // filter by 4 ot avoid having small strings in indexOf
      .filter((value: any) => passwordValue && passwordValue.indexOf(value) >= 0); // check if any string is matched

    return values && values.length === 0;
  });
});

Yup.addMethod(Yup.string, 'isPhoneValid', function (message) {
  return this.test('isPhoneValid', message, (phoneValue = '') => isValidPhoneNumber(phoneValue));
});

Yup.addMethod(Yup.string, 'emailCustom', function (message) {
  return this.test('emailCustom', message, (emailValue) => Email.validateEmail(emailValue));
});

Yup.addMethod(Yup.string, 'notEmpty', function (message) {
  return this.test('notEmpty', message, (value) => Required(value));
});

export const RgxSpace = /^\s+$/;

Yup.addMethod(Yup.string, 'notOnlySpaces', function (message) {
  return this.test('notOnlySpaces', message, (value) => value && !value.match(RgxSpace));
});

Yup.addMethod(Yup.string, 'customConditionalMsg', function (message) {
  return this.test('customConditionalMsg', message, (val) => !val);
});

Yup.addMethod(Yup.string, 'isValidYear', function (message) {
  return this.test('isValidYear', message, (val = '') => {
    if (!val) return true;

    return Number.isFinite(Number(val)) && val.toString().length === 4;
  });
});

export default Yup;
