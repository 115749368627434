import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '@minecraft.themes';
import { TypographyProps } from './types';
import { getTypographyStyles } from './utils';

export const BaseTypography = ({
  as: Tag = 'p',
  align,
  children,
  className,
  color,
  fontSize,
  fontWeight,
  hidden,
  id,
  italic,
  lineHeight,
  noWrap,
  overflowWrap,
  textWrap,
  qaId,
  style,
  testId,
  title,
  truncatable,
  truncatableNoWrap,
  underline,
  htmlFor,
  ...rest
}: TypographyProps) => {
  const theme = useTheme() as Theme;
  const mergedStyle = useMemo(
    () =>
      getTypographyStyles({
        align,
        color,
        fontSize,
        fontWeight,
        hidden,
        italic,
        lineHeight,
        noWrap,
        overflowWrap,
        textWrap,
        style,
        theme,
        truncatable,
        truncatableNoWrap,
        underline,
      }),
    [
      align,
      color,
      fontSize,
      fontWeight,
      hidden,
      italic,
      lineHeight,
      noWrap,
      overflowWrap,
      textWrap,
      style,
      theme,
      truncatable,
      truncatableNoWrap,
      underline,
    ]
  );
  const optionalProps = useMemo(() => {
    return {
      ...(typeof testId === 'string' && testId ? { 'data-testid': testId } : {}),
      ...(typeof qaId === 'string' && qaId ? { 'data-qa-id': qaId } : {}),
      ...(typeof className === 'string' && className ? { className } : {}),
      ...(typeof title === 'string' && title ? { title } : {}),
      ...(typeof id === 'string' && id ? { id } : {}),
      ...(typeof htmlFor === 'string' && htmlFor ? { htmlFor } : {}),
    };
  }, [testId, qaId, className, title, id, htmlFor]);

  return (
    <Tag {...rest} style={mergedStyle} {...optionalProps}>
      {children}
    </Tag>
  );
};
