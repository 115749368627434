import React, { FC } from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { BasicContainer, ContainerProps } from './Containers';

const StyledDialog = styled(Tooltip)`
  [x-placement] {
    opacity: 1;
  }

  [x-placement^='top'] {
    & .arrow::before {
      border-top-color: ${({ theme }) => theme.designSystem.borderColors.default};
    }
  }

  [x-placement^='bottom'] {
    & .arrow::before {
      border-bottom-color: ${({ theme }) => theme.designSystem.borderColors.default};
    }
  }

  .tooltip-inner {
    background: ${({ theme }) => theme.designSystem.bgColors.card};
    color: ${({ theme }) => theme.designSystem.textColors.default};
    border: 1px solid ${({ theme }) => theme.designSystem.borderColors.default};
    max-width: none;
  }

  .tooltip.show {
    opacity: 1;
  }
`;

export type DialogProps = {
  /** unique id value of an HTML element for the Dialog to render attached to */
  target: string;
  /** whether the Dialog is rendered or hidden */
  isOpen: boolean;
  /** amount of padding to place inside the tooltip around the children, defaults = "4" (1rem) */
  padding?: ContainerProps['padding'];
  /** accessible label for the dialog */
  'aria-label'?: string;
  /** qa id for the dialog */
  'data-qa-id'?: string;
  /** test id for the dialog */
  'data-testid'?: string;
};

/**
 * Dialog - wrapper component for an interactive tooltip / popover
 * see Design System reference: https://www.figma.com/design/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=392-45&node-type=frame&t=pjlXDJCYPZrV3ogR-0
 */
export const Dialog: FC<DialogProps> = ({
  children,
  target,
  isOpen,
  padding = 4,
  'aria-label': ariaLabel,
  'data-qa-id': dataQaId,
  'data-testid': dataTestId,
}) => {
  if (!target || !children || !isOpen) {
    return null;
  }

  return (
    <StyledDialog
      container="inline"
      placement="bottom"
      target={target}
      isOpen
      aria-label={ariaLabel}
      data-qa-id={dataQaId}
      data-testid={dataTestId}
    >
      <BasicContainer padding={padding}>{children}</BasicContainer>
    </StyledDialog>
  );
};
