import React, { FC, Suspense } from 'react';
import { Router } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import styled from 'styled-components';
import { Loading } from '@minecraft.loader';
import { NotificationProvider } from '@minecraft.notification-provider';
import { UserContextProvider } from '@blocs.auth';
import { WindowWidthContext } from '@minecraft.utils';
import { FeatureExperimentProvider } from '@blocs.features-experiments';
import { MaintenanceGuard } from '@blocs.maintenance';
import { Alert } from '@blocs.common';
import ImpactedServiceAlert from '@blocs.impacted-service-alert';
import { AppKey, InternationalizationProvider } from '@blocs.i18n';
import ErrorContextProvider from 'src/contextProviders/ErrorContextProvider';
import LoaderContextProvider from 'src/contextProviders/LoaderContextProvider';
import { UlaThemeProvider } from 'src/contextProviders/UlaThemeProvider';
import { PermissionsProvider as UlaPermissionsProvider } from 'src/contextProviders/PermissionsProvider';
import createApolloClient from 'lib/apollo';
import Routes from 'src/routes';
import routerHistory from 'utils/router-history';
import Meta from 'components/core/Meta';
import { getEnvironment } from '@minecraft.environment';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Body = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
`;

const App: FC = () => {
  const client = createApolloClient();
  const environment = getEnvironment();

  return (
    <Suspense fallback={<Loading />}>
      <InternationalizationProvider appName={AppKey.LOGIN} namespaces={['login']}>
        <FeatureExperimentProvider>
          <Router history={routerHistory}>
            <ApolloProvider client={client}>
              <GoogleOAuthProvider clientId={environment.GOOGLE_AUTH_CLIENT_ID}>
                <UserContextProvider>
                  <UlaPermissionsProvider>
                    <UlaThemeProvider>
                      <NotificationProvider>
                        <LoaderContextProvider>
                          <ErrorContextProvider>
                            <Body>
                              <Meta />
                              <Alert.GlobalAlertComponent />
                              <ImpactedServiceAlert />
                              <MaintenanceGuard routes={Routes} />
                            </Body>
                          </ErrorContextProvider>
                        </LoaderContextProvider>
                      </NotificationProvider>
                    </UlaThemeProvider>
                  </UlaPermissionsProvider>
                </UserContextProvider>
              </GoogleOAuthProvider>
            </ApolloProvider>
          </Router>
        </FeatureExperimentProvider>
      </InternationalizationProvider>
    </Suspense>
  );
};

export default WindowWidthContext.withWidthDataProvider(React.memo(App));
