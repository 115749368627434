import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import { Theme } from '@minecraft.themes';
import {
  computeBorderStyle,
  computeContainerStyle,
  computeBorderRadiusStyle,
  computeOffsetStyle,
  getGridItemStyles,
  getMarginClassnames,
  getWidthClassnames,
  getHeightClassnames,
  useMergedResponsiveProps,
} from '../helpers';
import { DivProps, BorderProps, ContainerStyleProps } from '../types';

const getBasicStyles = ({
  bgColor,
  borderBottom = false,
  borderColor,
  borderPlacement = 'none',
  borderStyle = 'solid',
  borderSize = 0.06,
  customBorders,
  hidden = false,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  customBordersRadius,
  top,
  right,
  bottom,
  left,
  textColor,
  theme,
  bgOpacity,
}: BorderProps & ContainerStyleProps & { theme: Theme }) => {
  return {
    color: theme.designSystem.textColors[textColor],
    ...computeContainerStyle({ theme, textColor, bgColor, bgOpacity }),
    ...computeBorderStyle({
      customBorders,
      borderPlacement,
      borderSize,
      borderColor,
      borderStyle,
      borderBottom,
      theme,
    }),
    display: hidden ? 'none' : 'block',
    maxHeight: typeof maxHeight === 'number' ? `${maxHeight}rem` : undefined,
    maxWidth: typeof maxWidth === 'number' ? `${maxWidth}rem` : undefined,
    minHeight: typeof minHeight === 'number' ? `${minHeight}rem` : undefined,
    minWidth: typeof minWidth === 'number' ? `${minWidth}rem` : undefined,
    ...computeBorderRadiusStyle({
      customBordersRadius,
    }),
    ...computeOffsetStyle({
      top,
      right,
      bottom,
      left,
    }),
  };
};

// This is a <div> wrapper element that supports setting, border, background color, and text color.
// It is used to create a consistent look and feel across the application.
// To set margin or padding, pass values supported by cn_m-<amount> or cn_p-<amount> from the global_overrides.css file.
// You can see an example here, where it is use to apply a dark drey background to the projects list page:
// https://www.figma.com/file/vSiom2PexyYl8jLqjZ7XyE/JIRA---CICN-141---Phase-1---Studio-Users---Update-Project-List-%26-utilize-AG-Grid-sorting-%26-grouping?node-id=2114-52662&t=b85NPE9FuVsvx9Tv-0
export const BasicContainer: FC<DivProps> = ({
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  w,
  maxW,
  minW,
  h,
  maxH,
  minH,
  children,
  smProps,
  mdProps,
  lgProps,
  xlProps,
  ...props
}) => {
  const {
    'aria-labelledby': ariaLabelledBy,
    'aria-label': ariaLabel,
    borderRadius = 'none',
    className,
    dataQaId,
    dataTestId,
    'data-testid': dataTestIdProp,
    'data-qa-id': dataQaIdProp,
    id,
    padding,
    qaId,
    role,
    testId,
    zIndex,
    ...styleProps
  } = useMergedResponsiveProps({ props, smProps, mdProps, lgProps, xlProps });
  const theme = useTheme() as Theme;
  const style = useMemo(() => getBasicStyles({ ...styleProps, theme }), [styleProps, theme]);
  const gridItemStyle = useMemo(
    () => ({
      ...getGridItemStyles({ ...styleProps, theme }),
      theme,
    }),
    [styleProps, theme]
  );

  const qaIdValue = qaId || dataQaId || dataQaIdProp;
  const testIdValue = testId || dataTestId || dataTestIdProp;

  const optionalProps = useMemo(() => {
    return {
      ...(typeof testIdValue === 'string' && testIdValue ? { 'data-testid': testIdValue } : {}),
      ...(typeof qaIdValue === 'string' && qaIdValue ? { 'data-qa-id': qaIdValue } : {}),
      ...(typeof ariaLabelledBy === 'string' && ariaLabelledBy ? { 'aria-labelledby': ariaLabelledBy } : {}),
      ...(typeof ariaLabel === 'string' && ariaLabel ? { 'aria-label': ariaLabel } : {}),
    };
  }, [testIdValue, qaIdValue, ariaLabelledBy, ariaLabel]);

  return (
    <div
      id={id}
      className={classNames(
        {
          [`cn_atom_zIndex-${zIndex}`]: zIndex !== undefined,
          [`cn_atom_rounded-${borderRadius}`]: !styleProps.customBordersRadius,
          [`cn_atom_p-${padding}`]: !!padding,
        },
        getMarginClassnames({ m, mx, my, mt, mr, mb, ml }, smProps, mdProps, lgProps, xlProps),
        getWidthClassnames({ w, maxW, minW }, smProps, mdProps, lgProps, xlProps),
        getHeightClassnames({ h, maxH, minH }, smProps, mdProps, lgProps, xlProps),
        className
      )}
      {...optionalProps}
      style={{ ...gridItemStyle, ...style }}
      role={role}
    >
      {children}
    </div>
  );
};
