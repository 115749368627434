import { Theme, DEFAULT_DESIGN_SYSTEM_THEME } from '@minecraft.themes';
import { LINE_HEIGHTS, FONT_WEIGHTS, FONT_SIZES } from './typographyConfig';
import { LineHeightProp, FontSizeProp, FontWeightProp, ColorProp, TypographyStyleProps } from './types';

export const getLineHeight = (lineHeight?: LineHeightProp): LineHeightProp => {
  if (Object.keys(LINE_HEIGHTS).includes(lineHeight)) {
    return lineHeight;
  }

  return 'default';
};

export const getFontSize = (fontSize?: FontSizeProp): FontSizeProp => {
  if (Object.keys(FONT_SIZES).includes(fontSize)) {
    return fontSize;
  }

  return 'bodyRegular';
};

export const getFontWeight = (fontWeight?: FontWeightProp): FontWeightProp => {
  if (Object.keys(FONT_WEIGHTS).includes(fontWeight)) {
    return fontWeight;
  }

  return 'regular';
};

export const getColor = (color?: ColorProp): ColorProp => {
  if (Object.keys(DEFAULT_DESIGN_SYSTEM_THEME.textColors).includes(color)) {
    return color;
  }

  return 'default';
};

export const getTypographyStyles = ({
  align,
  color,
  fontSize,
  fontWeight,
  italic,
  lineHeight,
  hidden,
  noWrap,
  overflowWrap,
  textWrap,
  style,
  theme,
  truncatable,
  truncatableNoWrap,
  underline,
}: TypographyStyleProps & { theme: Theme }) => {
  return {
    ...style,
    fontSize: FONT_SIZES[getFontSize(fontSize)],
    fontWeight: FONT_WEIGHTS[getFontWeight(fontWeight)],
    lineHeight: LINE_HEIGHTS[getLineHeight(lineHeight)],
    ...(italic ? { fontStyle: 'italic' } : {}),
    ...(underline ? { textDecoration: 'underline' } : {}),
    margin: 0,
    ...(truncatable || truncatableNoWrap
      ? {
          display: 'inline-block',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      : {}),
    ...(noWrap || truncatableNoWrap ? { whiteSpace: 'nowrap' } : {}),
    color: color === 'inherit' ? 'inherit' : theme.designSystem.textColors[getColor(color)],
    ...(overflowWrap ? { overflowWrap } : {}),
    ...(textWrap ? { textWrap } : {}),
    textAlign: align ?? 'left',
    ...(hidden ? { display: 'none' } : {}),
  };
};
